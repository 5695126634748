<template>
	<div class="details">
		<Commshop
		 :proData="transmitData"
		  />
		
		<div class="conten_body">  
			<div class="conten_left">
				
            
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
					<img class="" src="@/assets/images/services/6-1/2 (1).jpg">
					<img class="" src="@/assets/images/services/6-1/2 (2).jpg">
					<img class="" src="@/assets/images/services/6-1/2 (3).jpg">
					<img class="" src="@/assets/images/services/6-1/2 (4).jpg">
					<img class="" src="@/assets/images/services/6-1/2 (5).jpg">
					<img class="" src="@/assets/images/services/6-1/2 (6).jpg">
					<img class="" src="@/assets/images/services/6-1/2 (7).jpg">
					
					
				</div>
				
				
			</div>
			<!-- right -->
			<Phone/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Phone from "@/components/Phone.vue"
import Commshop from "@/components/Commshop.vue"
export default{
	components:{
      Phone,
      Commshop
	},
data(){
	return{
		transmitData:{
			title:"网站安全检测服务",
			content:"可用性监测，实时响应状态各类请求错误，整体响应时间，敏感词监测各类非法敏感词政治、博彩、色情，站点风险监测定时扫描漏洞、木马、暗链，篡改监测、网站图片篡改、网站首页篡改。",
            cc:"为客户提供网站安全检测服务",
			price:'在线咨询',
			url:require('../../assets/images/services/6-1/186_G_1530252247791.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:30px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.con_list img{
	width:100%;
}





}


</style>







